import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-santa-clarita-california.png'
import image0 from "../../images/cities/scale-model-of-downtown-newhall-in-santa-clarita-california.png"
import image1 from "../../images/cities/scale-model-of-public-art-\"fire-break\"-in-santa-clarita-california.png"
import image2 from "../../images/cities/scale-model-of-public-art-\"birds-of-valencia\"-in-santa-clarita-california.png"
import image3 from "../../images/cities/scale-model-of-public-art-\"streets\"-in-santa-clarita-california.png"
import image4 from "../../images/cities/scale-model-of-tesoro-adobe-park-in-santa-clarita-california.png"
import image5 from "../../images/cities/scale-model-of-william-s-hart-museum-in-santa-clarita-california.png"
import image6 from "../../images/cities/scale-model-of-six-flags-magic-mountain-in-santa-clarita-california.png"
import image7 from "../../images/cities/scale-model-of-mentryville-park-in-santa-clarita-california.png"
import image8 from "../../images/cities/scale-model-of-canyon-country-park-in-santa-clarita-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Santa Clarita'
            state='California'
            image={image}
            lat='34.3916641'
            lon='-118.54258600000003'
            attractions={ [{"name": "Downtown Newhall", "vicinity": "24268 Main St, Santa Clarita", "types": ["point_of_interest", "establishment"], "lat": 34.3782261, "lng": -118.5275044}, {"name": "Public Art \"Fire Break\"", "vicinity": "19190 Golden Valley Rd, Santa Clarita", "types": ["point_of_interest", "establishment"], "lat": 34.3922053, "lng": -118.46513629999998}, {"name": "Public Art \"Birds of Valencia\"", "vicinity": "Newhall Ranch Rd, Valencia", "types": ["point_of_interest", "establishment"], "lat": 34.4315116, "lng": -118.55694019999999}, {"name": "Public Art \"Streets\"", "vicinity": "Copper Hill Dr, Santa Clarita", "types": ["point_of_interest", "establishment"], "lat": 34.4538828, "lng": -118.56634939999998}, {"name": "Tesoro Adobe Park", "vicinity": "29350 Avenida Rancho Tesoro, Valencia", "types": ["park", "point_of_interest", "establishment"], "lat": 34.473535, "lng": -118.55648300000001}, {"name": "William S Hart Museum", "vicinity": "24151 Newhall Ave, Newhall", "types": ["museum", "point_of_interest", "establishment"], "lat": 34.3753971, "lng": -118.5257901}, {"name": "Six Flags Magic Mountain", "vicinity": "26101 Magic Mountain Pkwy, Valencia", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 34.4253305, "lng": -118.59721910000002}, {"name": "Mentryville Park", "vicinity": "1804, 27201 Pico Canyon Rd, Stevenson Ranch", "types": ["park", "point_of_interest", "establishment"], "lat": 34.3791724, "lng": -118.61101630000002}, {"name": "Canyon Country Park", "vicinity": "17615 Soledad Canyon Rd, Santa Clarita", "types": ["park", "point_of_interest", "establishment"], "lat": 34.4186585, "lng": -118.44178529999999}] }
            attractionImages={ {"Downtown Newhall":image0,"Public Art \"Fire Break\"":image1,"Public Art \"Birds of Valencia\"":image2,"Public Art \"Streets\"":image3,"Tesoro Adobe Park":image4,"William S Hart Museum":image5,"Six Flags Magic Mountain":image6,"Mentryville Park":image7,"Canyon Country Park":image8,} }
       />);
  }
}